<template>
  <div class="flex-1" :class="{ 'mb-5': !noMargin }">
    <div class="relative flex flex-1 flex-wrap items-stretch">
      <span
        v-if="$slots.icon"
        class="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 bg-transparent text-base items-center justify-center pl-3 py-3"
      >
        <slot name="icon" />
      </span>
      <span
        v-if="$slots.prefixes"
        ref="prefixesRef"
        class="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 bg-transparent text-base items-center justify-center py-3"
        :class="$slots.icon ? 'pl-9' : 'pl-3'"
      >
        <slot name="prefixes" />
      </span>

      <div
        class="relative flex items-center text-sm w-full placeholder-gray-400 rounded"
      >
        <input
          v-bind="$attrs"
          :name="name"
          :value="inputValue"
          class="text-sm w-full placeholder-gray-400 rounded input-field"
          :class="[
            { 'rounded-r-none': groupedRight },
            { 'rounded-l-none': groupedLeft },
            { error: !!error },
            { 'bg-gray-700 border-0 text-white': darkTheme },
            {
              default: componentStyle === 'default' && !darkTheme && !error
            },
            $slots.icon ? 'pl-9' : 'pl-2-5',
            smallSize ? 'py-[7px]' : 'py-2-5',
            { 'pr-12': hasSuffix },
            { 'pr-7': !hasSuffix && hasPadding && type !== 'date' },
            {
              login: componentStyle === 'login' && !error,
              'bg-transparent text-white': componentStyle === 'login',
              '-mb-0 mr-2': copy
            },
            { 'text-gray-100': darkTheme },
            {
              attention: componentStyle === 'attention' && !darkTheme && !error
            },
            componentStyle === 'default' || componentStyle === 'attention'
              ? 'text-headline'
              : 'text-gray-300'
          ]"
          :placeholder="placeholder"
          :type="type"
          :min="min"
          :max="max"
          :step="step"
          :maxlength="maxlength"
          :disabled="disabled"
          :role="`${name}-input`"
          :readonly="readonly"
          :autocomplete="autocompleteBehavior"
          :style="[
            $slots.prefixes
              ? $slots.icon
                ? `padding-left:${prefixesWidth + 42}px`
                : `padding-left:${prefixesWidth + 18}px`
              : ''
          ]"
          @focus="focused = true"
          @change="handleChange"
          @input="handleInput"
          @blur="onBlur"
          @keydown="$emit('keydown', $event)"
        />
        <span
          v-if="type === 'date' && dateFormat"
          class="date-format-placeholder absolute top-0.5 left-0.5 z-1 text-sm bg-white flex items-center rounded pl-2.5 pointer-events-none"
          :class="{
            'text-gray-700': customDateFormat
          }"
        >
          {{ customDateFormat || dateFormat }}
        </span>
        <copy-button
          v-if="copy"
          class="cursor-pointer"
          :text="value"
          :blue="true"
        >
          <icon-base
            class="place-self-center my-1"
            height="20"
            width="20"
            icon="copy"
            :iconName="$t('COMMON.COPY')"
          />
        </copy-button>
      </div>
      <label
        v-tooltipable
        v-show="
          (type == 'date' ||
            (!isNil(inputValue) &&
              String(inputValue).length &&
              !$slots.icon)) &&
          showLabel
        "
        for="input"
        class="absolute top-0 left-0 -mt-3 text-xs leading-6 ml-2-5 px-1 z-1 truncate max-w-17/20 pr-2"
        :class="[
          {
            'bg-login-bg': componentStyle === 'login',
            'bg-white':
              componentStyle === 'default' || componentStyle === 'attention'
          },
          {
            'text-red-600': error,
            'text-lendflow-green':
              componentStyle === 'login' && focused && !error,
            'text-blue-500': componentStyle === 'default' && focused && !error,
            'text-gray-400': componentStyle === 'default' && !focused,
            'text-yellow-500':
              componentStyle === 'attention' && !focused && !error
          }
        ]"
      >
        {{ placeholder }}
      </label>
      <slot name="text" />
      <span
        class="absolute right-2 bottom-1/2 transform translate-y-2-5 text-base sm:text-2xl text-gray-600"
      >
        <slot name="suffix" />
      </span>
      <span
        v-if="clearButtonEnabled && inputValue"
        class="absolute z-10 right-0.5 pt-3 mr-2-5 sm:mr-3-2 text-base sm:text-2xl cursor-pointer"
        :class="darkTheme ? 'text-white' : 'text-gray-600'"
        data-cy="clear-input"
        @click.stop.prevent="clearInput"
      >
        <icon-x />
      </span>
    </div>
    <div
      v-show="errorMessage || customError"
      role="errorMessage"
      class="pl-2 text-xxs sm:text-xs text-red-700 text-left"
    >
      {{ errorMessage || customError }}
    </div>
  </div>
</template>
<script lang="ts">
import { watch, computed, ref, defineComponent, type PropType } from "vue";
import { useField } from "vee-validate";
import CopyButton from "../buttons/CopyButton.vue";
import isNil from "lodash/isNil";
import { formatDateCustom } from "@/helpers/formatting";
import { useElementSize } from "@vueuse/core";

export default defineComponent({
  components: {
    CopyButton
  },
  name: "lf-input",
  // FIXME: because we don't use `inheritAttrs: false`, some standard input events (i.e. keydown) are firing twice
  // Before adding, we should investigate whether doing so would break any uses of this component
  emits: ["keyReleased", "clearInput", "keydown"],
  props: {
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Date],
      default: ""
    },
    maxlength: {
      type: Number,
      default: 300
    },
    placeholder: {
      type: String,
      default: ""
    },
    groupedRight: {
      type: Boolean,
      default: false
    },
    groupedLeft: {
      type: Boolean,
      default: false
    },
    smallSize: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: ""
    },
    max: {
      type: [Number, String],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    },
    step: {
      type: [Number, String],
      default: 0
    },
    clearButtonEnabled: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    darkTheme: {
      type: Boolean,
      default: false
    },
    componentStyle: {
      type: String as PropType<"default" | "login" | "attention">,
      default: "default"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String
    },
    noAutocomplete: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean
    },
    hasPadding: {
      type: Boolean,
      default: true
    },
    customError: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, slots }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange
    } = useField(props.name, undefined, {
      initialValue: props.value
    });

    const focused = ref(false);

    const prefixesRef = ref<HTMLElement | null>(null);
    const { width: prefixesWidth } = useElementSize(prefixesRef);

    const autocompleteBehavior = computed(() => {
      if (props.noAutocomplete) {
        return "off";
      }
      if (props.type === "password") {
        return "current-password";
      }
      if (props.type === "email" && props.componentStyle === "login") {
        return "username";
      }
      return undefined;
    });

    const customDateFormat = computed(() => {
      const formattedDate = formatDateCustom(`${inputValue.value}`);
      if (formattedDate === "-") {
        return null;
      }
      return formattedDate;
    });

    const hasSuffix = computed(() => slots.suffix);
    const error = computed(
      () => props.customError || errorMessage.value || props.hasError
    );

    const inputStyle = computed(() => {
      if (!slots.resizableElement) {
        return {};
      }
      const paddingLeft = prefixesWidth.value + (slots.icon ? 42 : 18);
      return { paddingLeft: paddingLeft + "px" };
    });

    const onBlur = (e: Event) => {
      handleBlur(e);
      focused.value = false;
    };

    const handleInput = (e: Event) => {
      emit(
        "keyReleased",
        (e.target as HTMLInputElement | undefined)?.value || ""
      );
    };

    const clearInput = () => {
      inputValue.value = "";
      emit("clearInput");
    };

    watch(
      () => props.value,
      (newVal) => {
        handleChange(newVal);
        inputValue.value = newVal;
      }
    );

    return {
      hasSuffix,
      handleChange,
      onBlur,
      errorMessage,
      inputValue,
      clearInput,
      focused,
      isNil,
      handleInput,
      customDateFormat,
      error,
      autocompleteBehavior,
      prefixesRef,
      prefixesWidth,
      inputStyle
    };
  }
});
</script>

<style scoped>
.placeholder-black input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @apply text-gray-600;
  opacity: 1; /* Firefox */
}

.placeholder-black:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @apply text-gray-600;
}

.login-bg {
  background: #272a40;
}

.placeholder-black::-ms-input-placeholder {
  /* Microsoft Edge */
  @apply text-gray-600;
}

.date-format-placeholder {
  height: calc(100% - 4px);
  width: 80%;
}

/* remove arrows for number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply appearance-none;
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  @apply hover:border-red-600 focus:border-red-600 focus-within:ring-transparent border border-red-600;
}

.login {
  @apply focus:border-lendflow-green hover:border-gray-600 focus-within:ring-transparent border-gray-500;
}

.default {
  @apply hover:border-gray-300 focus:border-primary border border-gray-200;
}

.attention {
  box-shadow: none;
  @apply border-yellow-400 border;
}
</style>
